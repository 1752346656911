// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-vacancies-js": () => import("./../../../src/pages/about/vacancies.js" /* webpackChunkName: "component---src-pages-about-vacancies-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-aesthetics-js": () => import("./../../../src/pages/aesthetics.js" /* webpackChunkName: "component---src-pages-aesthetics-js" */),
  "component---src-pages-aesthetics-medical-tattooing-js": () => import("./../../../src/pages/aesthetics/medical-tattooing.js" /* webpackChunkName: "component---src-pages-aesthetics-medical-tattooing-js" */),
  "component---src-pages-aesthetics-permanent-makeup-js": () => import("./../../../src/pages/aesthetics/permanent-makeup.js" /* webpackChunkName: "component---src-pages-aesthetics-permanent-makeup-js" */),
  "component---src-pages-beauty-eye-treatments-js": () => import("./../../../src/pages/beauty/eye-treatments.js" /* webpackChunkName: "component---src-pages-beauty-eye-treatments-js" */),
  "component---src-pages-beauty-hands-and-feet-js": () => import("./../../../src/pages/beauty/hands-and-feet.js" /* webpackChunkName: "component---src-pages-beauty-hands-and-feet-js" */),
  "component---src-pages-beauty-js": () => import("./../../../src/pages/beauty.js" /* webpackChunkName: "component---src-pages-beauty-js" */),
  "component---src-pages-beauty-laser-hair-removal-js": () => import("./../../../src/pages/beauty/laser-hair-removal.js" /* webpackChunkName: "component---src-pages-beauty-laser-hair-removal-js" */),
  "component---src-pages-beauty-massage-js": () => import("./../../../src/pages/beauty/massage.js" /* webpackChunkName: "component---src-pages-beauty-massage-js" */),
  "component---src-pages-beauty-other-treatments-js": () => import("./../../../src/pages/beauty/other-treatments.js" /* webpackChunkName: "component---src-pages-beauty-other-treatments-js" */),
  "component---src-pages-beauty-plasma-technology-js": () => import("./../../../src/pages/beauty/plasma-technology.js" /* webpackChunkName: "component---src-pages-beauty-plasma-technology-js" */),
  "component---src-pages-beauty-pure-facials-js": () => import("./../../../src/pages/beauty/pure-facials.js" /* webpackChunkName: "component---src-pages-beauty-pure-facials-js" */),
  "component---src-pages-beauty-waxing-js": () => import("./../../../src/pages/beauty/waxing.js" /* webpackChunkName: "component---src-pages-beauty-waxing-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hair-js": () => import("./../../../src/pages/hair.js" /* webpackChunkName: "component---src-pages-hair-js" */),
  "component---src-pages-hair-wedding-hair-js": () => import("./../../../src/pages/hair/wedding-hair.js" /* webpackChunkName: "component---src-pages-hair-wedding-hair-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-subscriptions-js": () => import("./../../../src/pages/subscriptions.js" /* webpackChunkName: "component---src-pages-subscriptions-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-trusted-partners-js": () => import("./../../../src/pages/trusted-partners.js" /* webpackChunkName: "component---src-pages-trusted-partners-js" */),
  "component---src-pages-vip-list-js": () => import("./../../../src/pages/vip-list.js" /* webpackChunkName: "component---src-pages-vip-list-js" */)
}

